import React from "react";
import PageLayout from "../components/pageLayout";

const About = () => {
  return (
    <PageLayout title={"About"} footer={false}>
      <div className="d-flex justify-content-center text-black">
        <div className={`container-fluid mr-2 mr-sm-4 ml-2 ml-sm-4`}>
          <div className="frontpage-height" />
          <div className="row ml-0 mr-0">
            <div className="p-0 col-sm-6 pb-5">
              <h3 className="h3-about">
                Studio Bara is a multidisciplinary practice currently based in Copenhagen, Denmark, exploring the limits
                of merging different visual art branches. The studio serves as a platform offering various
                architectural, graphic design, and art projects together with limited edition prints.
              </h3>
              <h5 className="mt-5 h5-about">Don´t hesitate to get in touch for collaborations or art requests.</h5>
            </div>

            <div className="col-sm-1 pr-5" />
            <div className="col-sm-5 p-0 pb-5 mb-5 div-about">
              <h5 className="mb-0 h5-about">Bara Strelcova</h5>
              <div className="mb-3">founder</div>
              <div>bara@studiobara.com</div>
              <div>DK-1708, København V</div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default About;
